import axios, { AxiosResponse } from 'axios'
import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Spinner from 'react-bootstrap/esm/Spinner'
import AuthContext from '../../../contexts/Auth'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { anOldHope } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { getQueryParams, getRouteParams } from '../../../helpers/Utils'
import Field from './Field/Field'
import useDataField from '../../../hooks/useDataField'

const replaceUrl = (url: string, key: string) => {
  if (url.indexOf(`{${key}}`) !== -1)
    return url.replace(`{${key}}`, '')

  if (url.indexOf(`{${key}?}`) !== -1)
    return url.replace(`{${key}?}`, '')

  return url
}

const Request: React.FC<any> = ({ request }: any) => {
  const { token } = useContext(AuthContext)

  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ showModal, setShowModal ] = useState<boolean>(false)
  const [ params, setParams ] = useState<any>({})
  const [ body, setBody ] = useState<any>({})
  const [ response, setResponse ] = useState<AxiosResponse | null>(null)

  const { parseData } = useDataField()

  const handleParamsChange = (name: string, value: any) => setParams({ ...params, [name]: value })

  const handleBodyChange = (name: string, value: any) => setBody({
    ...body,
    [name]: parseData(value),
  })

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setShowModal(true)
    setIsLoading(true)

    let url = Object.entries(params).reduce((url: string, [key, value]: any) => {
      return url.replace(`{${key}}`, value).replace(`{${key}?}`, value)
    }, request.url)

    Object.entries(getRouteParams(request.url)).map(([key]) => url = replaceUrl(url, key))
    Object.entries(getQueryParams(request.url)).map(([key]) => url = replaceUrl(url, key))

    if (typeof request?.body === 'object')
      Object.entries(request.body).map(([key]) => url = replaceUrl(url, key))

    const options = {
      method: request.method,
      url,
      headers: request.headers,
      data: body,
    }

    if (request.headers?.Authorization != null) {
      options.headers.Authorization = `Bearer ${token}`
    }

    if (request.headers?.['Content-Type'] === 'multipart/form-data') {
      const formData = new FormData()
      Object.entries(body).map(([key, value]) => formData.append(key, value as any))
      options.data = formData
    }

    axios.request(options).then(response => setResponse(response)).finally(() => setIsLoading(false))
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
      >
        <Modal.Body>
          {!isLoading ? (
            <SyntaxHighlighter language="curl" style={anOldHope} className="p-3 mb-0">
              {JSON.stringify(Array.isArray(response?.data) ? response?.data.slice(0, 100) : response?.data, null, 2)}
            </SyntaxHighlighter>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" />
            </div>
          )}
        </Modal.Body>
      </Modal>

      <form onSubmit={onSubmit}>
        {Object.entries(getRouteParams(request.url)).map(([key, value]) => (
          <Field name={key} value={value} onChange={handleParamsChange} key={key} required />
        ))}

        {Object.entries(getQueryParams(request.url)).map(([key, value]) => (
          <Field name={key} value={value} onChange={handleParamsChange} key={key} />
        ))}

        {typeof request?.body === 'object' && Object.entries(request.body).map(([key, value]) => (
          <Field name={key} value={value} onChange={handleBodyChange} key={key} />
        ))}

        {request.headers?.Authorization != null && (
          <div className="form-group mb-3">
            <label htmlFor="token">Authorization Token</label>
            <input type="text" className="form-control" id="token" placeholder="Authorization Token" value={`Bearer ${token}`} readOnly />
          </div>
        )}

        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-success">Send request</button>
        </div>
      </form>
    </>
  )
}

export default Request
