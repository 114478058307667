import React from 'react'
import { Link, useOutlet } from 'react-router-dom'
import IResource from '../../interfaces/IResource'
import { Container } from './Resource.styles'
import MethodEnum from '../../enums/MethodEnum'

const Resource: React.FC<any> = (resource: IResource) => {
  const outlet = useOutlet()

  if (outlet) return outlet

  const badge: any = {
    [MethodEnum.GET]: 'primary',
    [MethodEnum.POST]: 'success',
    [MethodEnum.PUT]: 'warning',
    [MethodEnum.DELETE]: 'danger',
  }

  return (
    <Container className="container-fluid p-4 pt-5 pb-5">
      <h1>{resource.name}</h1>

      {resource.description.length > 0 && (
        <p>{resource.description}</p>
      )}

      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Método</th>
            <th scope="col">Endpoint</th>
            <th scope="col">Descrição</th>
          </tr>
        </thead>

        <tbody>
          {resource.endpoints.map(({ request, ...endpoint }, index) => (
            <tr key={index}>
              <td>
                <span style={{ borderRadius: 0 }} className={`badge bg-${badge[request.method]}`}>{request.method}</span>
              </td>
              <td>
                <Link to={endpoint.slug}>{request.url.split('?')[0]}</Link>
              </td>
              <td>{endpoint.usage}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  )
}

export default Resource
