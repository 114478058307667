import React, { PropsWithChildren } from 'react'
import { GlobalStyle } from './App.styles'

const App: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      {children}
    </>
  )
}

export default App
