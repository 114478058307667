import MethodEnum from './enums/MethodEnum'
import IResource from './interfaces/IResource'

export const baseurl = process.env.REACT_APP_API_URL

export const schemas = {
  user: {
    id: 'number',
    name: 'string',
    email: 'string',
    usercode: 'number',
    role: {
      id: 'number',
      name: 'string',
    }
  },
  school: {
    id: 'number',
    fantasyName: 'string',
  },
  client: {
    id: 'number',
    name: 'string',
    primaryColor: 'string?',
    secondaryColor: 'string?',
    tertiaryColor: 'string?',
    quaternaryColor: 'string?',
    active: 'boolean?',
    logotipo: '{ url: \'string\', collapsed: { url: \'string\' } }?',
  },
  evaluation: {
    id: 'number',
    name: 'string',
    shortname: 'string',
    assessmentId: 'number',
    assessmentName: 'string',
    degreeId: 'number',
    degreeName: 'string',
    disciplineId: 'number',
    disciplineName: 'string',
    amountStudents: 'number',
    amountStudentsWithAnswers: 'number',
    startDate: 'Date',
    endDate: 'Date',
    duration: 'number',
    tagsMap: 'string[]',
    questionsMap: {
      default: 'string[]',
      spanish: 'string[]',
    }
  },
  media: {
    id: 'number',
    fileName: 'string',
    mimeType: 'string',
    size: 'number',
    createdAt: 'Date',
    resourceUrl: 'string'
  },
  answerCard: {
    evaluationId: 'number',
    platformId: 'number',
    schoolId: 'number',
    userId: 'number',
    usercode: 'number',
    languageId: 'number?',
    answers: 'string?',
    isComposition: 'boolean',
    barcode: 'string',
    deviceInfo: 'string?',
  },
}

export const resources: IResource[] = [
  {
    id: 1,
    name: 'Auth',
    description: 'Authentication and user information',
    slug: 'auth',
    endpoints: [
      {
        id: 1,
        name: 'Login',
        usage: 'Authenticates a user',
        request: {
          url: '/auth/login',
          method: MethodEnum.POST,
          headers: {
            'Content-Type': 'application/json',
          },
          body: {
            email: 'string',
            password: 'string',
            appId: 'string',
            roleId: 'number[]?'
          },
        },
        responses: [
          {
            status: 200,
            body: {
              user: schemas.user,
              accessToken: 'string',
              expiresAt: 'Date',
            },
          },
        ],
        slug: 'login',
      },
      {
        id: 2,
        name: 'User',
        usage: 'Get authenticated user information',
        request: {
          url: '/auth/user',
          method: MethodEnum.GET,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer <token>',
          },
        },
        responses: [
          {
            status: 200,
            body: schemas.user,
          },
        ],
        slug: 'user',
      },
      {
        id: 3,
        name: 'Forgot Password',
        usage: 'Sends an email to the user with a link to reset the password',
        request: {
          url: '/auth/forgot-password',
          method: MethodEnum.POST,
          headers: {
            'Content-Type': 'application/json',
          },
          body: {
            email: 'string',
            resetPasswordUrl: 'string'
          },
        },
        responses: [
          {
            status: 200,
            body: {},
          },
        ],
        slug: 'forgot-password',
      },
      {
        id: 4,
        name: 'Reset Password',
        usage: 'Resets the password of the user',
        request: {
          url: '/auth/reset-password',
          method: MethodEnum.POST,
          headers: {
            'Content-Type': 'application/json',
          },
          body: {
            rememberToken: 'string',
            password: 'string',
            passwordConfirmation: 'string'
          },
        },
        responses: [
          {
            status: 200,
            body: 'string',
          },
        ],
        slug: 'reset-password',
      },
    ],
  },
  {
    id: 2,
    name: 'Users',
    description: 'Users resource',
    slug: 'users',
    endpoints: [
      {
        id: 1,
        name: 'Users',
        usage: 'Get all users',
        request: {
          url: '/users?email={email?}&name={name?}&userCode={userCode?}',
          method: MethodEnum.GET,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer <token>',
          },
        },
        responses: [
          {
            status: 200,
            body: [
              {
                ...schemas.user,
              },
            ],
          },
        ],
        slug: 'get-users',
      },
      {
        id: 2,
        name: 'Schools',
        usage: 'Get schools of a user',
        request: {
          url: '/users/{userId}/schools',
          method: MethodEnum.GET,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer <token>',
          },
        },
        responses: [
          {
            status: 200,
            body: [
              {
                ...schemas.school,
              }
            ],
          },
        ],
        slug: 'schools',
      },
      {
        id: 3,
        name: 'Evaluations',
        usage: 'Get evaluations of a user',
        request: {
          url: '/users/{userId}/evaluations?schoolId={schoolId}&platformId={platformId}&evaluationId={evaluationId}',
          method: MethodEnum.GET,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer <token>',
          },
        },
        responses: [
          {
            status: 200,
            body: [
              {
                id: 'number',
                name: 'string',
                usercode: 'number',
              }
            ],
          },
        ],
        slug: 'evaluations',
      },
    ],
  },
  {
    id: 3,
    name: 'Clients',
    description: 'Clients resource',
    slug: 'clients',
    endpoints: [
      {
        id: 1,
        name: 'Clients',
        usage: 'Get all clients',
        request: {
          url: '/clients?email={email?}&',
          method: MethodEnum.GET,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer <token>',
          },
        },
        responses: [
          {
            status: 200,
            body: [
              {
                ...schemas.client,
              },
            ],
          },
        ],
        slug: 'get-clients',
      },
    ],
  },
  {
    id: 4,
    name: 'Schools',
    description: 'Schools resource',
    slug: 'schools',
    endpoints: [
      {
        id: 1,
        name: 'Schools',
        usage: 'Get all schools',
        request: {
          url: '/schools',
          method: MethodEnum.GET,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer <token>',
          },
        },
        responses: [
          {
            status: 200,
            body: [
              {
                ...schemas.school,
              },
            ],
          },
        ],
        slug: 'get-schools',
      },
    ],
  },
  {
    id: 5,
    name: 'Students',
    description: 'Students information',
    slug: 'students',
    endpoints: [
      {
        id: 1,
        name: 'Get Students',
        usage: 'Get students from the school',
        request: {
          url: '/schools/{schoolId}/students?degreeId={degreeId?}&classId={classId?}',
          method: MethodEnum.GET,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer <token>',
          },
        },
        responses: [
          {
            status: 200,
            body: {
              userId: 'number',
              userName: 'string',
              degreeId: 'number',
              degreeName: 'string',
              degreeShortName: 'string',
              classId: 'number',
              className: 'string',
            },
          },
        ],
        slug: 'get-students',
      },
    ],
  },
  {
    id: 6,
    name: 'Platforms',
    description: 'Platforms information',
    slug: 'platforms',
    endpoints: [
      {
        id: 1,
        name: 'Get Platforms',
        usage: 'Get platforms from the school',
        request: {
          url: '/schools/{schoolId}/platforms',
          method: MethodEnum.GET,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer <token>',
          },
        },
        responses: [
          {
            status: 200,
            body: {
              id: 'number',
              name: 'string',
              evaluations: [
                schemas.evaluation,
              ],
            },
          },
        ],
        slug: 'get-platforms',
      },
    ],
  },
  {
    id: 7,
    name: 'Evaluations',
    description: 'Evaluations information',
    slug: 'evaluations',
    endpoints: [
      {
        id: 1,
        name: 'Get Evaluations',
        usage: 'Get evaluations from the platform and school',
        request: {
          url: '/schools/{schoolId}/platforms/{platformId}/evaluations',
          method: MethodEnum.GET,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer <token>',
          },
        },
        responses: [
          {
            status: 200,
            body: [
              schemas.evaluation,
            ],
          },
        ],
        slug: 'get-evaluations',
      },
    ],
  },
  {
    id: 8,
    name: 'Answer Cards',
    description: 'Answer Cards information',
    slug: 'answer-cards',
    endpoints: [
      {
        id: 1,
        name: 'Get Answer Cards',
        usage: 'Get the answer cards',
        request: {
          url: '/answer-cards?schoolId={schoolId}&evaluationId={evaluationId}&platformId={platformId}',
          method: MethodEnum.GET,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer <token>',
          },
        },
        responses: [
          {
            status: 200,
            body: [
              {
                id: 'number',
                ...schemas.answerCard,
                originalMedia: schemas.media,
                croppedMedia: schemas.media,
                user: schemas.user,
                createdAt: 'Date',
              },
            ],
          },
        ],
        slug: 'get-answer-cards',
      },
      {
        id: 2,
        name: 'Create Answer Card',
        usage: 'Create an answer card',
        request: {
          url: '/answer-cards',
          method: MethodEnum.POST,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer <token>',
          },
          body: {
            ...schemas.answerCard,
            file: 'file',
          },
        },
        responses: [
          {
            status: 200,
            body: {
              id: 'number',
              ...schemas.answerCard,
              media: schemas.media,
              user: schemas.user,
              createdAt: 'Date',
            },
          },
        ],
        slug: 'create-answer-cards',
      },
    ],
  },
  {
    id: 9,
    name: 'Template Models',
    description: 'Template Models information',
    slug: 'template-models',
    endpoints: [
      {
        id: 1,
        name: 'Get Template Models',
        usage: 'Get the template models',
        request: {
          url: '/template-models?platformId={platformId}&evaluationId={evaluationId?}',
          method: MethodEnum.GET,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer <token>',
          },
        },
        responses: [
          {
            status: 200,
            body: [
              {
                id: 'number',
                createdAt: 'Date',
              },
            ],
          },
        ],
        slug: 'get-template-models',
      },
    ],
  },
]

const metadata = {
  baseurl,
  schemas,
  resources,
}

export default metadata
