export const getQueryParams = (url: string): any => {
  const params: any = {}

  const query: string = url.replace(/^\/[^?]+\?/, '')

  if (query.length && query.indexOf('&') !== -1) {
    const pairs = query.split('&')

    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=')

      if (pair[0] === '')
        continue

      if (pair[0].indexOf('?') !== -1)
        pair[0] = pair[0].slice(pair[0].indexOf('?') + 1)

      params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
    }
  }

  return params
}

export const getRouteParams = (url: string): any => {
  const params: any = {}

  const routeParams: RegExpMatchArray|null = url.match(/\/{(.*?)}/g)

  if (routeParams) {
    routeParams.forEach((param: string) => {
      param = param.replace('/', '')

      if (param.indexOf('?') !== -1)
        param = param.replace('?', '')

      const paramName = param.replace('{', '').replace('}', '')
      params[paramName] = `{${paramName}?}`
    })
  }

  return params
}
