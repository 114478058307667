import React, { useContext, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom'
import Console from '../layouts/Console/Console'
import Home from '../screens/Home/Home'
import metadata from '../metadata'
import IResource from '../interfaces/IResource'
import Resource from '../screens/Resource/Resource'
import Endpoint from '../screens/Endpoint/Endpoint'
import Login from '../screens/Login/Login'
import AuthContext, { AuthProvider } from '../contexts/Auth'
import Auth from '../layouts/Auth/Auth'

type Props = {
  guest?: string
}

const RequireAuth: React.FC<any> = ({ guest }: Props) => {
  const { isLoading, isAuthenticated } = useContext(AuthContext)

  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate(guest || '/login')
    }
  }, [guest, isAuthenticated, isLoading, navigate])

  if (!isAuthenticated) return null

  return <Outlet />
}

const Main: React.FC<any> = () => {
  return (
    <Router>
      <Routes>
        <Route element={<AuthProvider />}>
          <Route element={<RequireAuth guest="/login" />}>
            <Route element={<Console />}>
              <Route index element={<Home />} />

              {metadata.resources.map(((resource: IResource) => (
                <Route path={resource.slug} element={<Resource {...resource} />} key={resource.slug}>
                  {resource.endpoints.map(endpoint => (
                    <Route path={endpoint.slug} element={<Endpoint resource={resource} {...endpoint} />} key={endpoint.slug} />
                  ))}
                </Route>
              )))}
            </Route>
          </Route>

          <Route element={<Auth />}>
            <Route path="login" element={<Login />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  )
}

export default Main
