import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  padding-right: 0;

  > .row {
    height: 100%;
    justify-content: stretch;

    > .context,
    > .examples {
      height: 100%;
    }

    > .examples {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #2c3134;
      gap: 20px;

      > .position-sticky {
        position: sticky;
        top: calc(68px + 1rem);

        > pre {
          background-color: #1b1f22;
          margin-bottom: 0;
          color: #fff;
          border: 0;

          ::-webkit-scrollbar {
            width: 5px;
            height: 5px;
          }

          ::-webkit-scrollbar-button {
            width: 0px;
            height: 0px;
          }

          ::-webkit-scrollbar-thumb {
            background: #e1e1e1;
            border: 0px none #ffffff;
          }

          ::-webkit-scrollbar-thumb:hover {
            background-color: #ffffff;
          }

          ::-webkit-scrollbar-thumb:active {
            background-color: var(--secondary-color);
          }

          ::-webkit-scrollbar-track {
            background-color: #666666;
            border: 0px none #ffffff;
            border-radius: 0px;
          }
        }
      }
    }
  }
`
