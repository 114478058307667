import React from 'react'
import { Link } from 'react-router-dom'
import IResource from '../../interfaces/IResource'
import metadata from '../../metadata'
import { Nav } from './Sidebar.styles'

const Sidebar: React.FC<any> = () => {
  return (
    <div className="d-flex justify-content-between flex-column h-100">
      <Nav>
        <h6 className="nav-title text-uppercase">Console</h6>

        <ul className="nav flex-column m-0 p-0">
          {metadata.resources.map((resource: IResource) => (
            <li className="nav-item" key={resource.slug}>
              <Link to={resource.slug} className="nav-link active p-0 m-0">
                <span>{resource.name}</span>
              </Link>
            </li>
          ))}
        </ul>
      </Nav>
    </div>
  )
}

export default Sidebar
