import styled from 'styled-components'

export const Nav = styled.nav`
  position: sticky;
  top: calc(68px + 3rem);

  > ul {
    > li {
      > a {
        color: #1b1b1b;

        &:hover {
          color: var(--secondary-color);
        }
      }
    }
  }
`
