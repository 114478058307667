import styled from 'styled-components'

export const Header = styled.header`
  height: 68px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #1b1b1c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 999;
  color: #fff;
  top: 0;

  > .logotipo {
    width: 196px;

    > svg {
      fill: #fff;
      max-height: 45px;
    }
  }

  > .aside {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    > .appId {
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      overflow: hidden;
      color: #666;

      > .label {
        cursor: default;
        border-radius: 6px 0 0 6px;
        border: 1px solid #666;
        border-right: 0;
        background-color: #666;
        padding: 0.25rem 0.5rem;
        color: #fff;
      }

      > .value {
        padding: 0.25rem 0.5rem;
        border: 1px solid #666;
        border-radius: 0 6px 6px 0;
        border-left: 0;
      }
    }

    > .btn-logout {
      text-decoration: none;
      padding: 0.25rem 0.5rem;
      color: #fff;
      border: 0;
    }
  }
`

export const Main = styled.main`
  > .row {
    align-content: stretch;

    > .sidebar {
      min-height: calc(100vh - 68px);
      background-color: #f1f1f1;
    }

    > .content {
      min-height: calc(100vh - 68px);
    }
  }
`
