import React, { createContext, useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import axios from 'axios'
import useStickyState from '../hooks/useStickyState'
import * as $Auth from '../services/Auth'
import IUser from '../interfaces/IUser'

interface IAuthContextProps {
  user: IUser|null
  setUser: React.Dispatch<React.SetStateAction<IUser|null>>
  token: string|null
  setToken: React.Dispatch<React.SetStateAction<string|null>>
  expiresAt: string|null
  setExpiresAt: React.Dispatch<React.SetStateAction<string|null>>
  isAuthenticated: boolean
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  logout: () => void
}

const AuthContext = createContext<IAuthContextProps>({ } as IAuthContextProps)

export const AuthProvider: React.FC<any> = () => {
  const [ expiresAt, setExpiresAt ] = useStickyState<string|null>(null, 'expiresAt')
  const [ token, setToken ] = useStickyState<string|null>(null, 'token')
  const [ user, setUser ] = useState<IUser|null>(null)
  const [ isAuthenticated, setIsAuthenticated ] = useState<boolean>(false)
  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  const navigate = useNavigate()

  useEffect(() => {
    if (token?.length) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      $Auth.user().then(({ data }: any) => {
        setUser(data)
        setIsAuthenticated(true)
      }).catch(() => setToken(null)).finally(() => setIsLoading(false))
    } else {
      setIsLoading(false)
    }
  }, [setToken, token])

  const logout = useCallback(() => {
    localStorage.clear()

    setIsAuthenticated(false)
    navigate('/')
  }, [navigate])

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        token,
        setToken,
        expiresAt,
        setExpiresAt,
        isLoading,
        setIsLoading,
        isAuthenticated,
        setIsAuthenticated,
        logout,
      }}
    >
      <Outlet />
    </AuthContext.Provider>
  )
}

export default AuthContext
