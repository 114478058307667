import React, { useCallback, useContext, useState } from 'react'
import AuthContext from '../../contexts/Auth'
import * as $Auth from '../../services/Auth'
import axios from 'axios'
import ILogin from '../../interfaces/ILogin'
import { useLocation, useNavigate } from 'react-router-dom'

const Login: React.FC<any> = () => {
  const { setUser, setToken, setExpiresAt, setIsAuthenticated } = useContext(AuthContext)

  const [ login, setLogin ] = useState<ILogin>({} as ILogin)
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const navigate = useNavigate()
  const { search } = useLocation()
  const appId = new URLSearchParams(search).get('appId') ?? ''

  const attempt = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setIsLoading(true)

    $Auth.login(login).then(({ data: { user, accessToken: token, expiresAt } }: any) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      setUser(user)
      setToken(token)
      setExpiresAt(expiresAt)
      setIsAuthenticated(true)
      navigate('/')
    }).finally(() => setIsLoading(false))
  }, [login, setUser, setToken, setExpiresAt, setIsAuthenticated, navigate])

  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => setLogin({ ...login, [name]: value })

  return (
    <div className="card">
      <div className="card-header">API Docs da Evolucional</div>

      <form className="form" onSubmit={attempt} autoComplete="on">
        <div className="card-body">
          <div className="form-group mb-3 row">
            <label htmlFor="appId" className="col-3 col-form-label">App ID</label>
            <div className="col-9">
              <input
                type="text"
                name="appId"
                id="appId"
                className="form-control"
                onChange={handleInputChange}
                defaultValue={appId}
                disabled={isLoading}
                autoComplete="username"
                required
              />
            </div>
          </div>

          <div className="form-group mb-3 row">
            <label htmlFor="email" className="col-3 col-form-label">E-mail</label>
            <div className="col-9">
              <input
                type="text"
                name="email"
                id="email"
                className="form-control"
                onChange={handleInputChange}
                disabled={isLoading}
                required
              />
            </div>
          </div>

          <div className="form-group mb-3 row">
            <label htmlFor="password" className="col-3 col-form-label">Senha</label>
            <div className="col-9">
              <input
                type="password"
                name="password"
                id="password"
                className="form-control"
                onChange={handleInputChange}
                disabled={isLoading}
                required
              />
            </div>
          </div>

          <div className="options d-flex justify-content-between align-items-center">
            <div className="form-check">
              <input type="checkbox" name="remember" className="form-check-input" id="remember" />
              <label htmlFor="remember" className="form-check-label">Mantenha-me logado</label>
            </div>
          </div>
        </div>

        <div className="card-footer d-flex justify-content-end">
          <button type="submit" className="btn btn-dark">Login</button>
        </div>
      </form>
    </div>
  )
}

export default Login
