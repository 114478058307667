import styled from 'styled-components'

export const Header = styled.header`
  height: 68px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #1b1b1c;
  position: sticky;
  z-index: 999;
  color: #fff;
  top: 0;

  > .logotipo {
    > svg {
      fill: #fff;
      max-height: 45px;
    }
  }
`

export const Main = styled.main`
  > div {
    min-height: calc(100vh - 68px);
  }
`
