import React from 'react'
import { Header, Main } from './Auth.styles'
import { ReactComponent as Logotipo } from './../../resources/svgs/evolucional.svg'
import { Outlet } from 'react-router-dom'

const Auth: React.FC<any> = () => {
  return (
    <>
      <Header className="container-fluid ps-4 pe-4">
        <figure className="logotipo mb-0">
          <Logotipo />
        </figure>
      </Header>

      <Main className="container">
        <div className="col-12 col-md-4 offset-md-4 d-flex justify-content-center flex-column">
          <Outlet />
        </div>
      </Main>
    </>
  )
}

export default Auth
