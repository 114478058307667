import React, { useContext } from 'react'
import { Header, Main } from './Console.styles'
import { ReactComponent as Logotipo } from './../../resources/svgs/evolucional.svg'
import { Outlet } from 'react-router-dom'
import Sidebar from '../../components/Sidebar/Sidebar'
import AuthContext from '../../contexts/Auth'

const Console: React.FC<any> = () => {
  const { user, logout } = useContext(AuthContext)

  return (
    <>
      <Header className="container-fluid ps-4 pe-4">
        <figure className="logotipo mb-0">
          <Logotipo />
        </figure>

        <div className="aside">
          <div className="appId">
            <div className="label">APP ID</div>
            <div className="value">{user?.appId}</div>
          </div>

          <button className="btn btn-secondary btn-sm btn-logout pe-2 ps-2" onClick={logout}>Sair</button>
        </div>
      </Header>

      <Main className="container-fluid">
        <div className="row">
          <aside className="sidebar col-12 col-md-3 col-lg-2 p-4 pt-5 pb-5">
            <Sidebar />
          </aside>

          <article className="content col-12 col-md-9 col-lg-10">
            <Outlet />
          </article>
        </div>
      </Main>
    </>
  )
}

export default Console
