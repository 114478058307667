import React from 'react'

type Props = {
  name: string
  value: string
  required: boolean
  onChange: (name: string, value: any) => void
}

const isRequired = (value: string) => value.indexOf('?') === -1

const handleValue = (value: string) => {
  if ([
    'string',
    'string?',
    'number',
    'number?',
  ].includes(value))
    return ''

  if (value.match(/{\w+(\?)?}/) || value.indexOf('?') !== -1)
    return ''

  return value
}

const Field: React.FC<any> = ({ name, value, required, onChange }: Props) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) => {
    if (value === 'number' || value === 'boolean') {
      onChange(name, Number(e.target.value))
    } else {
      onChange(name, e.target.value === '' ? null : e.target.value)
    }
  }

  const handleFileOnChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(name, e.target.files?.length ? e.target.files[0] : null)

  if (value === 'boolean') {
    return (
      <div className="form-group mb-3">
        <label htmlFor={name}>{name}: {required || isRequired(value) ? (<small>(Obrigatório)</small>) : ''}</label>
        <select name={name} className="form-control" id={name} defaultValue={handleValue(value)} onChange={handleOnChange} required={required || isRequired(value)}>
          <option></option>
          <option value={1}>true</option>
          <option value={0}>false</option>
        </select>
      </div>
    )
  }

  if (value === 'file') {
    return (
      <div className="form-group mb-3">
        <label htmlFor={name}>{name}: {required || isRequired(value) ? (<small>(Obrigatório)</small>) : ''}</label>
        <input type="file" name={name} className="form-control" id={name} onChange={handleFileOnChange} required={required || isRequired(value)} />
      </div>
    )
  }

  return (
    <div className="form-group mb-3">
      <label htmlFor={name}>
        {name}: {required || isRequired(value) ? (<small>(Obrigatório)</small>) : ''}
      </label>
      <input
        type="text"
        name={name}
        className="form-control"
        id={name}
        defaultValue={handleValue(value)}
        placeholder={name}
        onChange={handleOnChange}
        required={required || isRequired(value)}
      />
    </div>
  )
}

export default Field
