const useDataField = () => {
  const parseData = (value: any) => {
    if (value === 'null')
      return null

    if (value === 'undefined')
      return undefined

    if (value === 'true' || value === 'false')
      return value === 'true'

    if (!isNaN(value) && isFinite(value))
      return Number(value)

    if (value.startsWith('[') && value.endsWith(']'))
      return JSON.parse(value)

    if (value.startsWith('{') && value.endsWith('}'))
      return JSON.parse(value)

    return value
  }

  return {
    parseData,
  }
}

export default useDataField
