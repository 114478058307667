import React from 'react'
import { Container } from './Home.styles'

const Home: React.FC<any> = () => {
  return (
    <Container className="container-fluid p-4 pt-5 pb-5">
      <h1>Console</h1>
      <p>
        A Web API da Evolucional permite explorar os endpoints por meio de uma interface fácil de usar.
      </p>
    </Container>
  )
}

export default Home
